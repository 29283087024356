/* eslint-disable import/prefer-default-export */
import * as events from '../events';
import Utilities from '../utilities';
import genericEvents from './generic-events';

export const FlowModule = {
    name: 'Vodacom ZA',
    logger: {},
    dispatcher: {},
    uiControl: {},
    tracker: {},
    integrator: {},
    territory: '',
    metadata: {},
    locale: '',
    identityToken: '',
    integratorIdentifyUrl: '',
    cancelBtns: [],
    identified: false,
    identity: '',
    identifyCalled: false,
    identifyCallFinished: false,
    internalCall: false,
    productUrl: '',
    productSlug: '',
    timeoutLimit: 180000, // 3 minutes,
    redirectTimeout: 5000,
    trackConversions: false,
    identifyTimeout: 15,
    timeout: null,
    flowSteps: {
        SPINNER: 'spinner',
        INIT: 'init',
        LANDER: 'lander',
        CONFIRM: 'confirm',
        SUBSCRIBE: 'subscribe',
        ERROR: 'error',
        FRAUD: 'fraud',
        SUBSCRIPTION_FAILED: 'subscription failed',
        SUBSCRIPTION_SUCCEEDED: 'subscription success',
        CANCEL: 'cancel',
        TIMEOUT: 'timeout',
        WIFI: 'wifi',
    },
    pageStates: {
        LANDER: 'lander',
        SUBSCRIBE: 'subscribe',
        SUCCESS: 'success',
        CONFIRM: 'confirm',
        SPINNER: 'spinner',
        ERROR: 'error',
        CANCEL: 'cancel',
        TIMEOUT: 'timeout',
        WIFI: 'wifi',
    },
    googleTags: {
        INIT: 'pmcon004',
        WIFI: 'pmcon008',
        SUBSCRIBE: 'pmcon005',
        CONFIRM: 'pmcon006',
        SUCCESS: 'pmcon007',
    },

    /**
     * Method to initialise the flow. This will be binding all the event listeners
     * needed to implement the two click flow
     */
    init() {
        this.integrator.appendBaseData({ territory: this.territory });
        this.cancelBtns = document.getElementsByClassName('flow-cancel-btn');
        this.logger.debug(`Flow (${this.name}): initialising flow`);
        this.bindClickEvents();
        this.bindTrackingEvents();
        this.bindIdentifyIframeEvents();
        genericEvents.bindWifiClickEvent(this);
        genericEvents.bindFlowExitEvent(this);
        this.setFlowStep(this.flowSteps.INIT);
        this.goToFirstStep();
        if (!this.internalCall) {
            this.initialiseHE();
        }
        this.checkCookieAndSubscription();
        this.setInactiveTimeout();
        this.setupExitHandler();
    },

    /**
     * Set the current step of the flow
     *
     * @param {string} flowStep
     */
    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;

        this.setPageStateForFlowStep(flowStep);
    },

    /**
     * Set the current state of the page
     *
     * @param {string} flowStep
     */
    setPageStateForFlowStep(flowStep) {
        switch (flowStep) {
        case this.flowSteps.INIT:
            this.loadGoogleTag(this.googleTags.INIT);
        // eslint-disable-next-line no-fallthrough
        case this.flowSteps.LANDER:
            if (this.metadata['page_attributes-enable-lander']) {
                this.uiControl.setPageState(this.pageStates.LANDER);
                break;
            }
        // eslint-disable-next-line no-fallthrough
        case this.flowSteps.SUBSCRIBE:
            this.uiControl.setPageState(this.pageStates.SUBSCRIBE);
            break;
        case this.flowSteps.CONFIRM:
            this.uiControl.setPageState(this.pageStates.CONFIRM);
            break;
        case this.flowSteps.SPINNER:
            this.uiControl.setPageState(this.pageStates.SPINNER);
            break;
        case this.flowSteps.CANCEL:
            this.uiControl.setPageState(this.pageStates.CANCEL);
            break;
        case this.flowSteps.TIMEOUT:
            this.uiControl.setPageState(this.pageStates.TIMEOUT);
            break;
        case this.flowSteps.WIFI:
            this.uiControl.setPageState(this.pageStates.WIFI);
            this.loadGoogleTag(this.googleTags.WIFI);
            break;
        case this.flowSteps.SUBSCRIPTION_SUCCEEDED:
            this.uiControl.setPageState(this.pageStates.SUCCESS);
            break;
        case this.flowSteps.ERROR:
        case this.flowSteps.FRAUD:
        case this.flowSteps.SUBSCRIPTION_FAILED:
        default:
            this.uiControl.setPageState(this.pageStates.ERROR);
            break;
        }
    },

    /**
     * If the user's been on the page for more than 60s without an action, time him out!
     */
    setInactiveTimeout() {
        // if we have a previous timeout, reset it
        this.clearInactiveTimeout();

        this.timeout = window.setTimeout(() => {
            this.logger.debug(`Flow (${this.name}): handling inactive timeout`);
            this.setFlowStep(this.flowSteps.TIMEOUT);
            this.tracker.track(events.PAGE_TIMEOUT);
            this.uiControl.showErrorMessage('Your session has timed out. Please click below to refresh the page and try again.', 'Retry');
        }, this.timeoutLimit);
        this.logger.debug(`Flow (${this.name}): set inactive timeout ${this.timeout}`);
    },

    /**
     * Remove previous inactive timeout
     */
    clearInactiveTimeout() {
        if (this.timeout) {
            this.logger.debug(`Flow (${this.name}): clearing timeout ${this.timeout}`);
            window.clearTimeout(this.timeout);
            this.timeout = null;
        }
    },

    conversionCallback(xhr) {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                // Call the redirect method in 1 second, if it has not already
                // been invoked by the conversion code
                const range = document.createRange();
                range.selectNode(document.getElementsByTagName('body')[0]);
                const documentFragment = range.createContextualFragment(xhr.responseText);
                document.body.appendChild(documentFragment);
                //3 seconds is the expected time for the conversion script to run
                setTimeout(() => {
                    this.conversionCompleted = true;
                }, 3000)
            } else if (xhr.status !== 200) {
                this.logger.error('[Vodacom ZA] Was unable to get conversion script. Redirecting to product')
                this.conversionCompleted = true;
            }
        }
    },

    conversion() {
        const self = this;
        const basePath = window.location.pathname;
        const http = new XMLHttpRequest();
        const conversionData = JSON.stringify({
            event: events.SECOND_CLICK,
            locale: this.metadata.locale,
        });

        http.open('POST', `${basePath}/conversion`);
        http.setRequestHeader('content-type', 'application/json');
        http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        http.onreadystatechange = function handleConversion() {
            self.conversionCallback(this);
        };
        http.onerror = function handleError() {
            window.console.error(`Got ERROR calling conversion endpoint ${conversionData}`);
        };
        http.ontimeout = function handleTimeout() {
            window.console.error(`Got TIMEOUT calling conversion endpoint ${conversionData}`);
        };
        http.send(conversionData);
    },

    /**
     * Binding the click events on the first button (#flow-subscribe) and on the
     * confirm button (#flow-confirm)
     */
    bindClickEvents() {
        this.logger.debug(`Flow (${this.name}): binding click events`);

        document.getElementById('flow-confirm').addEventListener('mouseup', () => {
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.setFlowStep(this.flowSteps.SPINNER);
            this.uiControl.showElement(this.uiControl.controls.spinner);
        });
        // binding cancel btn click events
        for (let i = 0; i < this.cancelBtns.length; i += 1) {
            const btn = this.cancelBtns[i];
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                this.clearInactiveTimeout();
                this.logger.debug(`Flow (${this.name}): handling button click - cancel`);
                this.setFlowStep(this.flowSteps.CANCEL);
                this.uiControl.hideElement(btn);
                this.uiControl.displayMessage('You have declined the subscription to NBA League Pass and will not be billed for the service.');
            });
        }

        // listen for lander click
        this.dispatcher.addEventListener(events.LANDER_CLICK, () => {
            // reset inactive timeout
            this.setInactiveTimeout();
            this.logger.debug(`Flow (${this.name}): handling first button click - subscribe`);
            this.setFlowStep(this.flowSteps.SUBSCRIBE);
            this.tracker.track(events.LANDER_CLICK);
            this.uiControl.hideElement(this.uiControl.controls.lander);
            this.uiControl.showElement(this.uiControl.controls.subscribe);
        });

        // listen for first click
        this.dispatcher.addEventListener(events.FIRST_CLICK, () => {
            // reset inactive timeout
            this.setInactiveTimeout();
            this.logger.debug(`Flow (${this.name}): handling first button click - subscribe`);
            this.setFlowStep(this.flowSteps.CONFIRM);
            this.tracker.track(events.FIRST_CLICK);
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.showElement(this.uiControl.controls.confirm);
            this.loadGoogleTag(this.googleTags.SUBSCRIBE);
        });

        // listen for second click
        this.dispatcher.addEventListener(events.SECOND_CLICK, () => {
            this.logger.debug(`Flow (${this.name}): handling second button click - confirm`);

            // here we clear the timeout entirely since the user has to wait for the result
            this.clearInactiveTimeout();
            this.tracker.track(events.SECOND_CLICK);
            this.loadGoogleTag(this.googleTags.CONFIRM);

            if (this.metadata['page_attributes-disable-second-click']) {
                this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
                this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                    error: 'second click button is disabled on admin panel',
                });
                return;
            }

            if(this.metadata['page_attributes-js-conversion-on-second-click']){
                this.conversion();
            }

            this.dispatcher.dispatchEvent(events.PRE_SUBSCRIBE);
            Utilities.waitForConditionAndExecute(
                this.isIdentifyCallFinished.bind(this),
                this.subscribeUser.bind(this),
            );

            
        });
    },

    /**
     * Add listener for postMessages
     */
    bindIdentifyIframeEvents() {
        this.logger.debug(`Flow (${this.name}): binding iframe events`);
        window.addEventListener('message', this.onMessage.bind(this), false);
    },

    showLander() {
        this.uiControl.showElement(this.uiControl.controls.lander);
    },

    showSubscribeButton() {
        this.uiControl.showElement(this.uiControl.controls.subscribe);
    },

    /**
     * Binding the tracking events
     */
    bindTrackingEvents() {
        this.dispatcher.addEventListener(events.FRAUD_DETECTED, (event) => {
            this.tracker.track(events.FRAUD_DETECTED, {
                data: {
                    reference: event.reference,
                    status_code: event.status_code,
                },
            });
            this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                reference: event.reference,
                status_code: event.status_code,
            });
        });

        this.dispatcher.addEventListener(events.SUBSCRIPTION_FAILED, (event) => {
            this.tracker.track(events.SUBSCRIPTION_FAILED, {
                data: {
                    reference: event.reference,
                    status_code: event.status_code,
                    error: event.error,
                    response_string: event.response_string,
                },
            });
        });

        this.dispatcher.addEventListener(events.SUBSCRIPTION_SUCCEEDED, (event) => {
            this.loadGoogleTag(this.googleTags.SUCCESS);
            this.tracker.track(events.SUBSCRIPTION_SUCCEEDED, {
                data: {
                    subscription: event.jwt,
                    reference: event.reference,
                },
            });
            const ts = Utilities.getQueryParameter(null, 'ts');

            if (this.trackConversions && ts !== 'SM') {
                const redirector = () => {
                    /* eslint no-undef: "off" */
                    if (typeof conversionError !== 'undefined') {
                        this.logger.error(`Flow (${this.name}): error in conversion script`, conversionError);
                    }
                    this.redirectToProduct(event.jwt);
                };

                this.uiControl.showElement(this.uiControl.controls.success.container);
                window.setTimeout(redirector.bind(this), 3500);
                this.uiControl.displayMessage('Please wait while you are redirected to the service');
                this.tracker.conversion({
                    subscription: event.jwt,
                });
            } else {
                if(this.metadata['page_attributes-js-conversion-on-second-click']){
                    //if something goes wrong in loading the JS conversion this will redirect the user to the product
                    setTimeout(() => {
                        this.conversionCompleted = true
                    }, 3500)
                    Utilities.waitForConditionAndExecute(
                        this.isConversionFinished.bind(this),
                        this.redirectToProduct(event.jwt).bind(this)
                    );
                }else{
                    this.redirectToProduct(event.jwt)
                }
            }
        });
    },

    /**
     * Redirect to product with an auth token
     *
     * @param {string} jwt
     */
    redirectToProduct(jwt) {
        // remove unload message
        window.onbeforeunload = null;

        this.tracker.track(events.REDIRECT_TO_PRODUCT);

        var base64Url = jwt.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        var payload = JSON.parse(jsonPayload);
        var url = new URL(payload['subscription']['login_url']);
        var authToken = url.searchParams.get('auth');


        let redirectUrl = this.productUrl;
        const queryConcat = (redirectUrl.indexOf('?') !== -1) ? '&' : '?';

        redirectUrl = `${this.productUrl}${queryConcat}auth=${authToken}&locale=${this.locale}`;

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);

        window.location.assign(redirectUrl);
    },

    /**
     * Check the query parameters and change flow to either the subscribe or confirm button
     */
    goToFirstStep() {
        if (Utilities.getQueryParameter(null, 'fc')) {
            this.logger.debug(`Flow (${this.name}): handling subscribe step from params`);
            this.setFlowStep(this.flowSteps.CONFIRM);
            // Send first click event with the property 'skipped'
            this.tracker.track(events.FIRST_CLICK, {
                data: {
                    skipped: true,
                },
            });
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.showElement(this.uiControl.controls.confirm);
        } else if (this.metadata['page_attributes-enable-lander']) {
            this.setFlowStep(this.flowSteps.LANDER);
            this.showLander();
        } else {
            this.setFlowStep(this.flowSteps.SUBSCRIBE);
            this.showSubscribeButton();
        }
    },

    /**
    * Start the identification process
    */
    identifyCustomer() {
        this.logger.debug(`Flow (${this.name}): identifying customer`);

        this.identifyCalled = true;
        const base = this.integratorIdentifyUrl.split('?')[0];

        setTimeout(this.checkIdentify.bind(this), this.identifyTimeout * 1000);
        this.metadata.idStartTime = Date.now();
        this.integrator.customHeIframeIdentify(
            base,
            'he',
            {
                returnUrl: window.location.href,
            }
        );
    },

    isConversionFinished(){
        return this.conversionCompleted
    },

    subscribeUser() {
        if (this.identified) {
            const onSuccess = (response) => {
                this.logger.info(`Flow (${this.name}): received integrator response`, response);

                if (response.status === 'SUCCESS') {
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_SUCCEEDED);
                    Utilities.setCookie('subscribed', new Date());
                    if (response.new) {
                        this.dispatcher.dispatchEvent(events.SUBSCRIPTION_SUCCEEDED, {
                            jwt: response.jwt,
                            reference: response.reference,
                        });
                    } else {
                        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                            data: {
                                jwt: response.jwt,
                                reference: response.reference,
                            },
                        });
                        this.redirectToProduct(response.jwt)
                    }
                } else {
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
                    this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                        reference: response.reference,
                        error: response.message || null,
                    });
                    this.uiControl.hideElement(this.uiControl.controls.spinner);
                    if (response.status_code === 402) {
                        if (response.message === 'PAYMENT_BLOCKED_CONTENT') {
                            this.tracker.track(events.CONTENT_BLOCKED)
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_DCB_DISABLED_NOT_ELIGIBLE', 'Retry');
                        } else if (response.message === 'PAYMENT_BLOCKED_CREDIT') {
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_INSUFFICIENT_CREDIT_ZA_VODACOM', 'Retry');
                        } else {
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
                        }
                    } else {
                        this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
                    }
                }
            };

            const onError = (response) => {
                this.uiControl.hideElement(this.uiControl.controls.spinner);

                if (response.status_code === 403) {
                    this.logger.error(`Flow (${this.name}): fraud detected`, response);
                    this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
                    this.setFlowStep(this.flowSteps.FRAUD);
                    this.dispatcher.dispatchEvent(events.FRAUD_DETECTED, {
                        reference: response.reference,
                        status_code: response.status_code,
                    });
                } else {
                    this.logger.error(`Flow (${this.name}): received error from integrator`, response);
                    this.setFlowStep(this.flowSteps.SUBSCRIPTION_FAILED);
                    this.dispatcher.dispatchEvent(events.SUBSCRIPTION_FAILED, {
                        reference: response.reference,
                        status_code: response.status_code,
                        error: response.message || null,
                        response_string: response.response_string || null,
                    });

                    if (response.status_code === 402) {
                        if (response.message === 'PAYMENT_BLOCKED_CONTENT') {
                            this.tracker.track(events.CONTENT_BLOCKED)
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_DCB_DISABLED_NOT_ELIGIBLE', 'Retry');
                        } else if (response.message === 'PAYMENT_BLOCKED_CREDIT') {
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR_INSUFFICIENT_CREDIT_ZA_VODACOM', 'Retry');
                        } else {
                            this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
                        }
                    } else {
                        this.uiControl.showErrorMessage('INTEGRATOR_ERROR', 'Retry');
                    }
                }
            };

            this.logger.info(`Flow (${this.name}): user is identified; attempting subscription`);

            this.integrator.heSubscribeRequestUsingTestMakeRequest({
                identity: this.identity,
                fraud_detection_id: this.metadata.fraud_transaction_id,
                metadata: this.metadata,
                mixpanel_tracking_id: this.metadata.event_tracking_id,
            }, onSuccess, onError);
        } else {
            this.tracker.track(events.SHOW_WIFI);
            this.uiControl.showWifiFlowWithRefreshPage();
        }
    },

    /**
    * Is the identification call finished?
    *
    * @return {boolean}
    */
    isIdentifyCallFinished() {
        return this.identifyCallFinished;
    },

    /**
     * Check the identification process on completion of the timeout (this.identifyTimeout)
     */
    checkIdentify() {
        this.logger.debug(`Flow (${this.name}): identification timeout`);
        this.timeoutReached = true;

        if (!this.isIdentifyCallFinished()) {
            this.logger.error(`Flow (${this.name}): identification timeout`);
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.setFlowStep(this.flowSteps.IDENTIFY_FAILURE);
            this.tracker.track(events.IDENTIFY_ERROR, {
                data: {
                    error: `request timeout reached - ${this.identifyTimeout}s`,
                },
            });
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
        }
    },

    onMessage(event) {
        this.logger.debug(`Flow (${this.name}): received iframe message`, event);

        const originDomain = event.origin.split('://');
        const integratorDomain = this.integratorIdentifyUrl.split('://');
        const baseIntegratorDomain = integratorDomain[1].split('?')[0];
        const validDomains = [
            baseIntegratorDomain,
        ];

        if (typeof originDomain[1] === 'undefined' || originDomain[1] === '' || validDomains.indexOf(originDomain[1]) === -1) {
            this.logger.error(`Flow (${this.name}): failed to validate message origin.`, originDomain, validDomains);
            return;
        }
        const heResponse = event.data;
        var endtime = Date.now()
        const trackingData = {
            identity: heResponse.identity,
            identified: heResponse.identified,
            reference: heResponse.reference,
            timedelta: endtime - this.metadata.idStartTime,
        };


        if (heResponse.status === 'SUCCESS' && heResponse.status_code === 200) {
            this.identity = heResponse.identity;
            this.identified = heResponse.identified;
            this.metadata.identity = heResponse.identity;

            if (heResponse.identified) {
                this.tracker.track(events.IDENTIFY_SUCCESS, { data: trackingData });
            } else {
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.setFlowStep(this.flowSteps.WIFI);
                this.tracker.track(events.IDENTIFY_FAILURE, { data: trackingData });
                this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                if (this.metadata['page_attributes-enable-lander']) {
                    this.uiControl.hideElement(this.uiControl.controls.lander);
                }
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showWifiFlowWithRefreshPage();
            }
        } else { // 'ERROR'
            this.setFlowStep(this.flowSteps.IDENTIFY_FAILURE);
            this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
            this.tracker.track(events.IDENTIFY_ERROR, { data: trackingData });
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.uiControl.hideElement(this.uiControl.controls.subscribe);
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.uiControl.showErrorMessage('INTEGRATOR_ERROR');
        }
        this.identifyCallFinished = true;
    },

    // Event for exit button clicked.
    setupExitHandler() {
        const button = [].slice.call(document.getElementsByClassName('exit'));
        button.forEach((elem) => {
            elem.addEventListener('click', (e) => {
                e.preventDefault();
                window.location.href = 'https://google.co.uk';
            });
        });
    },

    checkCookieAndSubscription() {
        if (Utilities.checkCookieExists('subscribed')) {
            this.logger.info(`Flow (${this.name}): Cookie "subscribed" exists so checking if we have a subscription`);
            this.tracker.track(events.CHECKING_SUBSCRIPTION);
            this.uiControl.showElement(this.uiControl.controls.spinner);

            Utilities.waitForConditionAndExecute(
                this.isIdentifyCallFinished.bind(this),
                this.checkSubscriptionRequest.bind(this)
            );
        }
    },

    checkSubscriptionRequest() {
        const onError = (response) => {
            this.uiControl.hideElement(this.uiControl.controls.spinner);
            this.tracker.track(events.SUBSCRIPTION_NOT_FOUND, {
                data: {
                    status: response.status,
                },
            });
        };

        const onSuccess = (response) => {
            this.uiControl.hideElement(this.uiControl.controls.confirm);
            this.uiControl.hideElement(this.uiControl.controls.subscribe);

            if (response.status === 200) {
                const redirectUrl = response.jwt;
                this.tracker.track(events.SUBSCRIPTION_EXISTS, {
                    data: {
                        redirectUrl,
                    },
                });
                this.tracker.track(events.REDIRECT_TO_PRODUCT);

                window.location.href = redirectUrl;
            } else {
                this.tracker.track(events.SUBSCRIPTION_NOT_FOUND, {
                    data: {
                        status: response.status,
                    },
                });
            }
        };

        if (this.identified) {
            this.integrator.checkSubscriptionRequest({
                identity: this.identity,
                product_slug: this.productSlug,
            }, onSuccess, onError);
        }
    },

    initialiseHE() {
        // check if we have an identity, and if so set it on this flow
        // Just checking if we were preidentified
        const success = Utilities.getQueryParameter(null, 'success');
        const identity = Utilities.getQueryParameter(null, 'identity');
        if (identity && success) {
            this.identifyCallFinished = true;
            let alteredURL = Utilities.removeQueryParameter('identity', window.location.href);
            alteredURL = Utilities.removeQueryParameter('success', alteredURL);
            window.history.pushState({}, null, alteredURL);
            if (success === '1') {
                this.logger.debug(`Flow (${this.name}): identity token retrieved`);
                this.identity = identity;
                this.identified = true;
                this.metadata.identity = identity;
                this.tracker.track(events.IDENTIFY_SUCCESS, {
                    data: {
                        identity: this.identity,
                    },
                });

                this.checkCookieAndSubscription();
            } else {
                this.loadGoogleTag(this.googleTags.WIFI);
                this.setFlowStep(this.flowSteps.WIFI);
                this.tracker.track(events.IDENTIFY_FAILURE);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                if (this.metadata['page_attributes-enable-lander']) {
                    this.uiControl.hideElement(this.uiControl.controls.lander);
                }
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.dispatcher.dispatchEvent(events.IDENTIFY_FAILURE);
                this.uiControl.showWifiFlowWithRefreshPage();
            }
        } else {
            this.identifyCustomer();
        }
    },

    /**
     * @param page
     */
    loadGoogleTag(tag) {
        if (this.metadata['page_attributes-load-google-tags']) {
            const id = '10617449';
            this.loadGlobalGoogleTagManagerScripts(id);
            this.createTagScript(id, tag);
            this.createTagImg(id, tag);
        }
    },

    createTagScript(id, tag) {
        const existingGoogleTagScript = document.getElementById('google_tag');
        if (existingGoogleTagScript) {
            existingGoogleTagScript.remove();
        }

        const googleTagScript = document.createElement('script');
        googleTagScript.id = 'google_tag';
        googleTagScript.innerHTML = `gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-${id}/invmedia/${tag}+standard' });`;
        document.body.appendChild(googleTagScript);
    },

    createTagImg(id, tag) {
        const axel = Math.random();
        const a = axel * 10000000000000;

        const existingGoogleTagImg = document.getElementById('google_tag_img');
        if (existingGoogleTagImg) {
            existingGoogleTagImg.remove();
        }

        const googleTagImg = document.createElement('img');
        googleTagImg.id = 'google_tag_img';
        // eslint-disable-next-line no-undef
        googleTagImg.src = `https://ad.doubleclick.net/ddm/activity/src=${id};type=invmedia;cat=${tag};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;ord=${a}?`;
        googleTagImg.width = 1;
        googleTagImg.height = 1;
        googleTagImg.alt = '';
        document.body.appendChild(googleTagImg);
    },

    loadGlobalGoogleTagManagerScripts(id) {
        const existingGlobalGoogleTagManagerScript = document.getElementById('global_google_tag_manager');
        if (existingGlobalGoogleTagManagerScript) {
            existingGlobalGoogleTagManagerScript.remove();
        }
        const globalGoogleTagManagerScript = document.createElement('script');
        globalGoogleTagManagerScript.id = 'global_google_tag_manager';
        globalGoogleTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=DC-${id}`;
        globalGoogleTagManagerScript.async = true;
        document.head.appendChild(globalGoogleTagManagerScript);

        const existingGlobalGoogleTagScript = document.getElementById('global_google_tag');
        if (existingGlobalGoogleTagScript) {
            existingGlobalGoogleTagScript.remove();
        }
        const globalGoogleTagScript = document.createElement('script');
        globalGoogleTagScript.id = 'global_google_tag';
        globalGoogleTagScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'DC-${id}');`;
        document.head.appendChild(globalGoogleTagScript);
    },
};

window.FlowModule = FlowModule;
